<template>
  <div>
    <keep-alive>
      <router-view :key="$route.fullPath"></router-view>
    </keep-alive>
    <Footer v-if="footerVisible" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import Footer from "@/components/Footer";
export default {
  components: { Footer },
  computed: {
    ...mapState(["navVisible", "footerVisible"]),
  },
};
</script>
