import { fetchProfile } from "@/api/index";

const actions = {
  /**
   * LOGIN
   */
  LOGIN({ commit }, data) {
    commit("LOGIN", data);
    commit("SET_USER_INFO", data);
  },
  /*
   * LOGOUT
   */
  LOGOUT({ commit }) {
    commit("LOGOUT");
    window.location.href = "/";
  },

  /**
   * fetch User 정보
   */
  getAccountInfo({ commit, dispatch }) {
    fetchProfile().then((res) => {
      if (res.data.status == 200 && res.data.data) {
        let data = {
          email: res.data.data.email,
          isAdmin: res.data.data.isAdmin,
          _id: res.data.data._id,
          id: res.data.data.id,
          mobileNo: res.data.data.mobileNo,
          name: res.data.data.name,
          team: res.data.data.team.teamName,
          userType: res.data.data.userType,
          status: res.data.data.status, //회원 가입 처리 상태
        };
        commit("SET_USER_INFO", data);
      } else {
        dispatch("LOGOUT");
      }
    });
  },
  SET_NAVBAR({ commit }, data) {
    commit("SET_NAVBAR", data);
  },
  SET_NAVBAR_VISIBLE({ commit }, data) {
    commit("SET_NAVBAR_VISIBLE", data);
  },
  SET_FOOTER({ commit }, data) {
    commit("SET_FOOTER", data);
  },
  SET_USER_INFO({ commit }, data) {
    commit("SET_USER_INFO", data);
  },
};

export default actions;
