const state = {
  id: null,
  _id: null,
  email: "", //이메일
  status: "",
  mobileNo: "",
  username: "",
  name: null,
  team: null,
  teamName: null,
  userType: "",
  navbarState: "-1",
  isLogin: false,
  isAdmin: false,
};
export default state;
