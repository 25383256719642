import axios from "axios";
import { baseUrl, headers } from "../config/env";

/**
 * create(POST) / fetch(GET) / remove(DELETE) / update (PUT)
 *
 */

// 유저
export function createUser(data) {
  return axios.post(baseUrl + "/account/join", data, { headers });
}

export function login(data) {
  return axios.post(baseUrl + "/account/login", data, { headers });
}

export function fetchProfile() {
  return axios.get(baseUrl + `/account/profile`, { headers });
}

export function updatePassword(data) {
  return axios.put(baseUrl + "/account/userpw/update", data, { headers });
}

export function updateUser(data) {
  return axios.put(baseUrl + "/account/user/update", data, { headers });
}

export function fetchUserList(params) {
  return axios.get(baseUrl + `/account/user/list`, { params, headers });
}

export function deleteUser(data) {
  return axios.put(baseUrl + `/account/user/delete`, data, { headers });
}

export function createNewPassword(id) {
  return axios.put(baseUrl + `/account/user/pw/${id}`, { headers });
}

// 팀
export function createTeam(data) {
  return axios.post(baseUrl + "/account/team/create", data, { headers });
}

export function fetchTeamList(params) {
  return axios.get(baseUrl + `/account/team/list`, { params, headers });
}

export function updateTeam(data) {
  return axios.put(baseUrl + "/account/team/update", data, { headers });
}

export function deleteTeam(data) {
  return axios.put(baseUrl + `/account/team/delete`, data, { headers });
}

// 메타데이터
export function createMetadata(data) {
  return axios.post(baseUrl + "/metadata/create", data, { headers });
}

export function createAllMetadata(data) {
  return axios.post(baseUrl + `/metadata/create/many`, data, { headers });
}

export function updateMetadata(data) {
  return axios.put(baseUrl + "/metadata/update", data, { headers });
}

export function updateMultipleMetadata(data) {
  return axios.patch(baseUrl + "/metadata/update/multi", data, { headers });
}

export function fetchMetadataList(params) {
  return axios.get(baseUrl + `/metadata/list`, { params, headers });
}

export function fetchMetadataList2(params) {
  return axios.get(baseUrl + `/metadata/list/v2`, { params, headers });
}

export function fetchMetadataDetail(id) {
  return axios.get(baseUrl + `/metadata/detail/${id}`, { headers });
}

export function fetchMetadataDetails(ids) {
  return axios.get(baseUrl + `/metadata/details?ids=${ids}`, { headers });
}

export function fetchMetadataLog(id) {
  return axios.get(baseUrl + `/metadata/metadatalog/${id}`, { headers });
}

export function deleteMetadata(id) {
  return axios.delete(baseUrl + `/metadata/${id}`, {
    headers,
  });
}

export function deleteMetadatas(ids) {
  return axios.delete(baseUrl + `/metadata/multi/${ids}`, {
    headers,
  });
}

export function fetchYoutubeDashboardData() {
  return axios.get(baseUrl + "/youtube-dashboard", {
    headers,
  });
}

export function uploadImage(file) {
  const form = new FormData();
  form.append("image", file);

  return axios.post(baseUrl + "/home/file/upload", form, { headers });
}

// export function fetchProfileById(id) {
//   return axios.get(baseUrl + `/api/accounts/profile/${id}`, { headers });
// }
// export function createAccountId(data) {
//   return axios.post(baseUrl + "/api/accounts/find/accountId", data);
// }

// export function fetchScrapList(params) {
//   return axios.get(baseUrl + `/api/accounts/scrap/list`, { params, headers });
// }

// export function fetchPostList(params) {
//   return axios.get(baseUrl + `/api/accounts/post/list`, { params, headers });
// }

// export function updateUpgrade(data) {
//   return axios.put(baseUrl + `/api/accounts/upgrade`, data, { headers });
// }

// export function updatePassword(data) {
//   return axios.put(baseUrl + `/api/accounts/pwd`, data, {
//     headers,
//   });
// }

// export function createScrap(data) {
//   return axios.post(baseUrl + "/api/accounts/scrap", data, { headers });
// }

//회원탈퇴
// export function createWithdraw() {
//   return axios.get(baseUrl + "/api/accounts/withdraw", { headers });
// }

// ------------------------------------- 기존소스

// export function fetchUserDetails(userId) {
//   return axios.get(baseUrl + `/api/accounts/detail/${userId}`, { headers });
// }

// export function deleteUser(userId) {
//   return axios.delete(baseUrl + `/account/user/delete`, {
//     headers,
//   });
// }
// export function deleteUser(userId) {
//   return axios.delete(baseUrl + `/api/admin/user/${userId}`, {
//     headers,
//   });
// }

// export function findAccountPwd(data) {
//   return axios.post(baseUrl + "/api/accounts/resetPwd", data, { headers });
// }

// export function findAccountID(data) {
//   return axios.post(baseUrl + "/api/accounts/findAccountID", data, { headers });
// }
// export function fetchBookmark(params) {
//   return axios.get(baseUrl + `/api/accounts/bookmark`, { params, headers });
// }

// export function updateNewPwd(data) {
//   return axios.put(baseUrl + `/api/accounts/newPwd`, data, {
//     headers,
//   });
// }
// export function verifyMobile(data) {
//   return axios.post(baseUrl + `/api/auth/sms/verify`, data, { headers });
// }

// export function createCertNumber(data) {
//   return axios.post(baseUrl + `/api/auth/sms`, data, { headers });
// }
